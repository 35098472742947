.home-container {
  color: #fff;
  .topLeft-cnt {
    background: rgba(0, 0, 0, 0.35);
    padding: 16px 50px;
    border-radius: 25px;

    p {
      font-size: 28.5px;
      color: #fff;
      margin-bottom: 0px;
      text-shadow: 3px 3px 5px #000;
      font-family: AgencyFB-Bold;
      line-height: 1.2;

      @media (max-width: 991px) {
        font-size: 20px;
        margin-right: 10px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
      padding: 16px 15px;
      align-items: center;
      margin-top: -10px;
    }
  }

  .topRight-cnt {
    background: url("/assets/images/rewards-bg.png") no-repeat center center;
    background-size: 100% 100%;
    width: auto;
    height: auto;
    padding: 22px 28px;

    .rewardsCounter {
      background: #2b2278;
      border-radius: 25px;
      padding: 6px 25px;
      color: #fff;
      -webkit-box-shadow: inset 0px 1px 29px 8px rgba(0, 0, 0, 0.44);
      -moz-box-shadow: inset 0px 1px 29px 8px rgba(0, 0, 0, 0.44);
      box-shadow: inset 0px 1px 29px 8px rgba(0, 0, 0, 0.44);
      font-size: 30px;
      font-family: AgencyFB-Bold;

      @media (max-width: 991px) {
        font-size: 20px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
        padding: 6px 10px;
      }
    }
  }
  .bottomLeft-cnt {
    p {
      font-size: 28.5px;
      color: #fff;
      margin-bottom: 0px;
      text-shadow: 3px 3px 5px #000;
      font-family: AgencyFB-Bold;

      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
  }
  .claim-btn {
    background: url("/assets/images/claim-button.png") no-repeat center center;
    background-size: 100% 100%;
    width: auto;
    height: auto;
    font-size: 30px;
    text-shadow: 2px 2px 1px #000;
    color: #fff;
    font-family: AgencyFB-Bold;
    padding: 4px 18px 9px 18px;
    line-height: normal;
    filter: drop-shadow(5px 8px 3px rgba(0, 0, 0, 0.5));

    @media (max-width: 991px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  .fight-btn {
    background: url("/assets/images/fight-button.png") no-repeat center center;
    background-size: 100% 100%;
    width: calc(100% - 10px);
    height: auto;
    font-size: 38px;
    text-shadow: 2px 3px 3px #000;
    color: #fff;
    font-family: AgencyFB-Bold;
    padding: 4px 18px 9px 18px;
    line-height: normal;
    filter: drop-shadow(5px 8px 2px rgba(0, 0, 0, 0.7));
    margin-left: 5px;

    @media (max-width: 1199px) {
      font-size: 26px;
    }
  }

  .mons-card-cnt {
    .fight-btn {
      margin-top: -15px;
    }
  }

  @media (min-width: 1300px) {
    .container {
      max-width: 1240px;
    }
  }
  .mons-card-cnt {
    padding: 30px;

    @media (max-width: 991px) {
      padding: 0px;
    }
  }

  @media (max-width: 991px) {
    padding: 0px;

    .homeTopcnt,
    .homeBottomcnt {
      padding: 15px;
    }
  }
  .slick-prev,
  .slick-next {
    height: auto;
    width: auto;
    z-index: 1;
  }

  .slick-slider {
    ul.slick-dots {
      bottom: 45px;

      li {
        button {
          &:before {
            background: #edf1e7;
            color: #edf1e7;
            opacity: 1;
            border-radius: 50%;
            width: 18px;
            height: 18px;

            @media (max-width: 767px) {
              width: 15px;
              height: 15px;
            }
          }
        }

        &.slick-active {
          button {
            &:before {
              background: #00baff;
              color: #00baff;
            }
          }
        }
      }
    }
  }

  .bottomSlider {
    .mons-card-cnt {
      padding: 10px;
    }
    .slick-slide {
      padding: 95px 0px;
    }
    .slick-slide svg {
      transform: scale(1);
      transition: all 0.5s;
    }
    .slick-slide.slick-active.slick-current svg {
      transform: scale(1.3);
    }
    .slick-next {
      right: 75px;
    }
    .slick-prev {
      left: 75px;
    }
  }
}
svg {
  width: 100%;
  height: auto;
  margin: auto;
}
.pntNo {
  pointer-events: none;
}

.cls-enBrn,
.cls-plName,
.cls-ntName,
.cls-rgTx {
  stroke-linejoin: round;
}

.cls-enBrnBg,
.cls-star {
  fill-rule: evenodd;
}

.cls-enBrn,
.cls-plName,
.cls-ntName,
.cls-rgTx {
  stroke: #000;
}

.cls-enBrn,
.cls-plName,
.cls-ntName,
.cls-rgTx {
  stroke-width: 3px;
  stroke-width: 1.5px;
}

.cls-plName {
  font-size: 56.27px;
}

.cls-enBrn,
.cls-plName,
.cls-ntName,
.cls-star,
.cls-rgTx {
  fill: #fff;
}

.cls-enBrn,
.cls-plName,
.cls-ntName,
.cls-mntName,
.cls-rgTx {
  font-family: "AgencyFB-Bold";
  font-weight: 700;
}

.cls-ntName {
  font-size: 48px;
}

.cls-mntName,
.cls-rgTx {
  font-size: 60px;
}

.pwrBar {
  stroke: #0c0d10;
  stroke-width: 3px;
  stroke-width: 1.5px;
  fill-rule: evenodd;
  fill-opacity: 0.6;
}
.cls-pwrFill {
  fill: #ff8a00;
  filter: url(#filter-orange);
}

.cls-accBar {
  fill: #0c0d10;
  stroke-width: 3px;
  stroke-width: 1.5px;
  stroke: #000;
  fill-rule: evenodd;
  fill-opacity: 0.6;
}
.cls-accFill {
  fill: #2aff00;
  filter: url(#filter-green);
}

.cls-enBrn {
  font-size: 43px;
}

.cls-enBrnBg {
  fill-opacity: 0.7;
}

.accTx {
  fill: #00d2ff;
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
}
.pwrTx {
  font-size: 75px;
  fill: #ff2155;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
}
/*--||
Card Design Start
||--*/

/*--||
Card-2 Design Start
||--*/
.plName-2 {
  font-size: 56.27px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
.ntName-2 {
  font-size: 48px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
.mntName-2 {
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
}

.star-2 {
  fill-rule: evenodd;
  fill: #ffffff;
  filter: url(#filter-star2);
}
.star-22 {
  fill-rule: evenodd;
  fill: #ffffff;
  filter: url(#filter-star22);
}
.star-23 {
  fill-rule: evenodd;
  fill: #ffffff;
  filter: url(#filter-star23);
}

.barLbl {
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}

.barBg-1 {
  fill-rule: evenodd;
  stroke: #0c0d10;
  fill-opacity: 0.6;
  stroke-width: 3px;
}
.barFill-1 {
  fill: #ff8a00;
  filter: url(#filter-barFill-1);
}

.barBg-2 {
  fill: #0c0d10;
  fill-rule: evenodd;
  fill-opacity: 0.6;
  stroke-width: 3px;
  stroke: #000000;
}
.barFill-2 {
  fill: #2aff00;
  filter: url(#filter-barFill-2);
}

.barVal {
  font-size: 43px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}

.tmrBg {
  fill: #00bea3;
  fill-opacity: 0.5;
  fill-rule: evenodd;
}
.tmrTx {
  font-size: 43px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
/*--||
Card-2 Design End
||--*/

/*--||
Card-3 Design Start
||--*/

.edtName {
  filter: url(#filter-edtName);
  text-anchor: middle;
  font-family: AMCAPEternal;
  text-transform: uppercase;
  font-size: 48px;
  fill: #ffffff;
  font-style: italic;
}
.mntName-3 {
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
}
.ntName-3 {
  font-size: 48px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
.plName-3 {
  font-size: 56.27px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}

.barBg-1-3 {
  stroke: #0c0d10;
  fill-opacity: 0.6;
  stroke-width: 3px;
  fill-rule: evenodd;
}
.barFill-1-3 {
  fill: #ff8a00;
  filter: url(#filter-barFill-1-3);
}

.barBg-2-3 {
  fill: #0c0d10;
  fill-opacity: 0.6;
  stroke-width: 3px;
  stroke: #000;
  fill-rule: evenodd;
}
.barFill-2-3 {
  fill: #2aff00;
  filter: url(#filter-barFill-2-3);
}

.barLbl-3 {
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
.barVal-3 {
  font-size: 43px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}

.tmrBg-3 {
  fill: #cf4dff;
  fill-opacity: 0.5;
  fill-rule: evenodd;
}
.tmrTx-3 {
  font-size: 90px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #ffffff;
  stroke-width: 1.5px;
  stroke: #000000;
  stroke-linejoin: round;
}
/*--||
Card-3 Design End
||--*/
.def-txt {
  font-size: 60px;
  font-family: "AgencyFB-Bold";
  font-weight: 700;
  fill: #fff;
  stroke: #000;
  stroke-width: 1.5px;
  stroke-linejoin: round;
}
.def-board {
  fill-opacity: 0.7;
  fill-rule: evenodd;
}
.home-container {
  @media (max-width: 991px) {
    .slick-slide {
      padding: 95px 0px;
      position: relative;
      z-index: 1;
      transition: all 0.5s;
      opacity: 0.3;
    }
    .slick-slide svg {
      transform: scale(1);
      transition: all 0.5s;
    }
    .slick-slide.slick-active.slick-center.slick-current svg {
      transform: scale(1.5);
    }
    .slick-slide.slick-active.slick-center.slick-current {
      z-index: 2;
      opacity: 1;
    }
    .slick-prev,
    .slick-next {
      height: auto;
      width: 40px;
      z-index: 1;
    }
    .slick-prev {
      left: 50px;
    }
    .slick-next {
      right: 50px;
    }

    .slick-slide.slick-active.slick-center {
      .mons-card-cnt {
        .fight-btn {
          margin-top: 80px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .slick-slide.slick-active.slick-center {
      .mons-card-cnt {
        .fight-btn {
          margin-top: 55px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
    .slick-slide.slick-active.slick-center {
      .mons-card-cnt {
        .fight-btn {
          margin-top: 30px;
        }
      }
    }
    .slick-slide {
      .mons-card-cnt {
        .fight-btn {
          font-size: 20px;
        }
      }
    }
  }
}
.home-container {
  .bottomSlider {
    .slick-slider {
      &.threeCardSlider {
        .slick-slide {
          opacity: 1;
          .mons-card-cnt {
            svg {
              max-width: 50%;
              transform: scale(1);
              height: auto;
              overflow: hidden;

              &.normal-card {
                display: none;
                height: 0px;
              }
              &.active-card {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          &.slick-active.slick-current {
            .mons-card-cnt {
              svg {
                &.normal-card {
                  display: none;
                }
                &.active-card {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.slick-slide {
  .mons-card-cnt {
    svg.active-card {
      height: 0px;
      overflow: hidden;
      transition: all 0.5s;
    }
  }
}
.slick-slide.slick-center.slick-current {
  .mons-card-cnt {
    svg.normal-card {
      height: 0px;
      overflow: hidden;
      transition: all 0.5s;
    }
    svg.active-card {
      height: auto;
      overflow: hidden;
      transition: all 0.5s;
    }
  }
}
.modal-dialog {
  &.win-modal {
    background: url("/assets/images/win-bg.png") no-repeat center center;
    background-size: contain;
    max-width: 89%;
    min-width: 98%;
    min-height: 895px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1350px) {
      min-width: 1290px;
      min-height: 895px;
    }
    .modal-content {
      background: transparent;
      border: none;

      .modal-body {
        h3 {
          font-size: 60px;
          color: #fff;
          font-weight: bold;

          @media (max-width: 991px) {
            font-size: 24px;
          }
        }
        h1 {
          font-size: 80px;
          color: #ffcc00;
          font-weight: bold;

          @media (max-width: 991px) {
            font-size: 30px;
          }
        }
      }
    }
  }

  &.lose-modal {
    background: url("/assets/images/lose-bg.png") no-repeat center center;
    background-size: contain;
    max-width: 89%;
    min-width: 98%;
    min-height: 895px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1350px) {
      min-width: 1290px;
      min-height: 895px;
    }
    .modal-content {
      background: transparent;
      border: none;

      .modal-body {
        h3 {
          font-size: 60px;
          color: #fff;
          font-weight: bold;

          @media (max-width: 991px) {
            font-size: 24px;
          }
        }
        h1 {
          font-size: 80px;
          color: #ffcc00;
          font-weight: bold;

          @media (max-width: 991px) {
            font-size: 30px;
          }
        }
      }
    }
  }
}
.loadingScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #01209370;
  z-index: 100;
  .loadingCoin {
    position: absolute;
    width: 150px;
    top: 0;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
    animation: loading 1s linear infinite forwards;
    z-index: 101;
  }
  .hero {
    position: absolute;
    height: 40%;
    bottom: 0;
    right: -50%;
    animation: fromRight 1s ease-out 1 forwards;
  }
  .enemy {
    position: absolute;
    height: 40%;
    top: 0;
    left: -50%;
    animation: fromLeft 1s ease-out 1 forwards;
  }
  @keyframes loading {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes fromRight {
    0% {
      right: -50%;
    }
    100% {
      right: 0;
    }
  }
  @keyframes fromLeft {
    0% {
      left: -50%;
    }
    100% {
      left: 0;
    }
  }
}