@font-face {
  font-family: AgencyFB;
  src: url("/assets/fonts/AgencyFB-Reg.woff2"),
    url("/assets/fonts/AgencyFB-Reg.woff"),
    url("/assets/fonts/AgencyFB-Reg.TTF"), url("/assets/fonts/AgencyFB-Reg.svg"),
    url("/assets/fonts/AgencyFB-Reg.otf"), url("/assets/fonts/AgencyFB-Reg.eot");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: AgencyFB-Bold;
  src: url("/assets/fonts/AgencyFB-Bold.woff2"),
    url("/assets/fonts/AgencyFB-Bold.woff"),
    url("/assets/fonts/AgencyFB-Bold.TTF"),
    url("/assets/fonts/AgencyFB-Bold.svg"),
    url("/assets/fonts/AgencyFB-Bold.otf"),
    url("/assets/fonts/AgencyFB-Bold.eot");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: AgencyFB-Bold;
  src: url("/assets/fonts/AgencyFB-Bold.woff2"),
    url("/assets/fonts/AgencyFB-Bold.woff"),
    url("/assets/fonts/AgencyFB-Bold.TTF"),
    url("/assets/fonts/AgencyFB-Bold.svg"),
    url("/assets/fonts/AgencyFB-Bold.otf"),
    url("/assets/fonts/AgencyFB-Bold.eot");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AMCAPEternal";
  src: url("/assets/fonts/AMCAPEternal.eot") format("EOT"),
    url("/assets/fonts/AMCAPEternal.otf") format("OpenType"),
    url("/assets/fonts/AMCAPEternal.svg") format("svg"),
    url("/assets/fonts/AMCAPEternal.ttf") format("TrueType"),
    url("/assets/fonts/AMCAPEternal.woff") format("woff"),
    url("/assets/fonts/AMCAPEternal.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AgencyFB-Reg";
  src: url("/assets/fonts/AgencyFB-Reg.eot") format("EOT"),
    url("/assets/fonts/AgencyFB-Reg.otf") format("OpenType"),
    url("/assets/fonts/AgencyFB-Reg.svg") format("svg"),
    url("/assets/fonts/AgencyFB-Reg.woff") format("woff"),
    url("/assets/fonts/AgencyFB-Reg.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Burbank Big Condensed Bold";
  src: url("/assets/fonts/BurbankBigCondensed-Bold.otf") format("OpenType");
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  background: url("/assets/images/home-bg.png") no-repeat center center;
  background-size: cover;
  min-height: 100vh;
  font-family: AgencyFB;

  @media (max-width: 991px) {
    background: url("/assets/images/mobile-bg.png") no-repeat top center;
    background-size: cover;
  }
}

.home-container {
  padding: 30px;
}
.org-txt {
  color: #ffa200 !important;
}
.grn-txt {
  color: #24ff00 !important;
}
.red-txt {
  color: #e02222 !important;
}
.blue-txt {
  color: #00fffc !important;
}
